import React, {PropsWithChildren} from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import Header from './header';
import './layout.scss';

const Layout: React.FC = ({children}: PropsWithChildren<{}>) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <div className="container">
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <footer>© BuildUp {new Date().getFullYear()}. All rights reserved.</footer>
      </div>
    </>
  );
};

export default Layout;
