import {Link} from 'gatsby';

import React, {useState, ReactElement} from 'react';
import {Collapse, Nav, Navbar, NavbarToggler, NavItem, NavLink, NavbarBrand} from 'reactstrap';

interface HeaderProps {
  siteTitle: string;
}

export default function Header(props: HeaderProps): ReactElement {
  const {siteTitle} = props;
  const [open, setOpen] = useState(false);

  return (
    <Navbar color="secondary" dark expand="md" className="mb-3 rounded-bottom">
      <NavbarBrand tag={Link} to="/">
        {siteTitle}
      </NavbarBrand>
      <NavbarToggler onClick={(): void => setOpen(!open)} />
      <Collapse isOpen={open} navbar>
        <Nav className="ms-auto" navbar>
          <NavItem>
            <NavLink active tag={Link} to="/blog">
              Blog
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink active tag={Link} to="/library">
              Library
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink active tag={Link} to="/blog/2018-03-04-welcome-to-build-up">
              About
            </NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
}
